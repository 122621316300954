import parse from "./parse.config";

const backend = {
  protocol: "https://",
  base_url: "opendash.kompetenzzentrum-siegen.digital",
  wsprotocol: "wss://",
};

export default {
  parse,
  backend,
  frontpage: {
    qrcode: {
      url: backend.protocol + backend.base_url,
      userurl: parse.url,
      usercode: parse.applicationId,
    },
    frontPageTiles: [{
      class: "opendash",
      icon: "fa fa-area-chart fa-3x",
      label: "Monitoring",
      action: "/dashboard"
    }, {
      class: "openreport",
      icon: "fa fa-file-text-o fa-3x",
      label: "Reporting",
      action: "/reporting/list/"
    }/*,

    {
      class: "openadmin",
      icon: "fa fa-cogs fa-3x",
      label: "Sensorkonfiguration",
      action: function action() {
        window.open("https://bde.kompetenzzentrum-siegen.digital/admin/openware/sensors", "_blank");
      }
      //roles: ["Admin"],

    }, {
      class: "openuser",
      icon: "fa fa-users fa-3x",
      label: "Nutzerverwaltung",
      action: function action() {
        window.open("https://bde.kompetenzzentrum-siegen.digital/admin/parse", "_blank");
      }
      //roles: ["Admin"],

    }, {
      class: "opencheck",
      icon: "fa fa-mobile fa-3x",
      label: "Mobile Datenerfassung",
      action: function action() {
        window.open("https://openapp.kompetenzzentrum-siegen.digital/", "_blank");
      }
      //roles: ["Admin"]

    }, {
      class: "opencheck",
      icon: "fa fa-wpforms fa-3x",
      label: "BDE-Management",
      action: function action() {
        window.open("https://bde.kompetenzzentrum-siegen.digital/admin/bde/units", "_blank");
      }
      //roles: ["Admin"]

    }*/],
  },
  showFrontpage: true,
  playground: false,
  reporting: {},
  locations: true,
};
